class ParallaxTiles {
    constructor() {
        this.tiles = document.querySelectorAll(
            "[data-parallax-tiles]"
        );

        if (!this.tiles) {
            return;
        }

        window.addEventListener('gsap-ready', () => {
            this.init();
        })
    }

    init() {
        this.tiles.forEach((tileGroup) => {
            const horizontalDirection = tileGroup.getAttribute('data-horizontal-direction') ?? 'right';
            const startOffset = tileGroup.getAttribute('data-start-offset') ?? '50%';
            const animationType = tileGroup.getAttribute('data-animation-type') ?? 'fade';

            const isFade = animationType === 'fade';

            window.gsap.to(tileGroup, {
                yPercent: isFade ? 100 : 0,
                xPercent: () => {
                    if(isFade){
                        return horizontalDirection === 'right' ? 10 : -10;
                    }
                    return 0;
                },
                opacity: 0,
                ease: "none",
                scrollTrigger: {
                    trigger: tileGroup,
                    start: `top+=${startOffset} center`,
                    end: "bottom center",
                    scrub: true
                },
            });
        });
    }
}

new ParallaxTiles();
